<template>
    <div class="datasoures">
        <div style="display: flex;">
            <Headbuttom></Headbuttom>
            <Tabheader @excelExportData="excelExportData" @handFromshow="handFromshow"></Tabheader>
        </div>

        
        <div class="fromshea" v-show="iShow">
            <el-form :inline="true" size="small"  :model="searchParams" class="demo-form-inline">

                <el-form-item :label="$t('devtable.bm')">
                    <Select @handleCheckChange="handleCheckChange"></Select>
                </el-form-item>
                <el-form-item :label="$t('devtable.sbxlhD')">
                    <el-input v-model="searchParams.serial_number" :placeholder="$t('devtable.sbxlhD')"></el-input>
                </el-form-item>
                <el-form-item :label="$t('devtable.zt')">
                    <el-select v-model="searchParams.unlock_state" :placeholder="$t('devtable.qxz')">
                    <el-option :label="$t('devtable.wxz')" value="-1"></el-option>
                    <el-option :label="$t('devtable.j')" value="0"></el-option>
                    <el-option :label="$t('devtable.c')" value="1"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item :label="$t('devtable.kmlx')">
                    <el-select v-model="searchParams.open_type" :placeholder="$t('devtable.qxz')">
                    <el-option :label="$t('devtable.wxz')" value="-1"></el-option>
                    <el-option :label="$t('devtable.xkkm')" value="1"></el-option>
                    <el-option :label="$t('devtable.yckm')" value="2"></el-option>
                    <el-option :label="$t('devtable.lykm')" value="3"></el-option>
                    <el-option :label="$t('devtable.mmkm')" value="4"></el-option>
                    <el-option :label="$t('devtable.zwkm')" value="5"></el-option>
                    <el-option :label="$t('devtable.rlkm')" value="6"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item :label="$t('devtable.xm')">
                    <el-input v-model="searchParams.name" :placeholder="$t('devtable.xm')"></el-input>
                </el-form-item>
                <el-form-item :label="$t('devtable.gh')">
                    <el-input v-model="searchParams.number" :placeholder="$t('devtable.gh')"></el-input>
                </el-form-item>
                <!-- //:picker-options="pickerOptions" -->

                 <el-form-item :label="$t('devtable.qsrq')" prop="name">
                    <el-date-picker  type="date"  
                    :placeholder="$t('devtable.xzrq')" v-model="searchParams.start_time" 
                    style="width: 100%;" value-format="yyyy-MM-dd"></el-date-picker>
                </el-form-item>

                <el-form-item :label="$t('devtable.jsrq')" prop="name">
                    <el-date-picker  type="date" format="yyyy-MM-dd"
                        value-format="yyyy-MM-dd" :placeholder="$t('devtable.xzrq')" 
                        v-model="searchParams.end_time" style="width: 100%;" ></el-date-picker>
                </el-form-item>
                
                <!-- <el-form-item :label="$t('devtable.jsrq')">
                    <el-date-picker type="date" format="yyyy-MM-dd"
                        value-format="yyyy-MM-dd" :placeholder="$t('devtable.xzrq')" v-model="searchParams.end_time" style="width: 100%;"></el-date-picker>
                </el-form-item> -->
            <el-form-item>
                <el-button type="primary" @click="onSubmit">{{$t('devtable.cx')}}</el-button>
            </el-form-item>
        </el-form>
        </div>
        <Tables @handlpages="handlpages" @handleSizeChange="handleSizeChange" @handimage="handimage" :tableLabel="tableLabel" :tableData="tableData" :flags="flags" :configs="configs"></Tables>
        <Imagesdiolg ref="imgdiolg" @dialogVisibletrue="dialogVisibletrue" @handleClose="handleClose">
           
            <el-image :src="src">
            <div slot="error" class="image-slot">
                <i class="el-icon-picture-outline"></i>
            </div>
            </el-image>
        </Imagesdiolg>
    </div>
</template>
<script>
//照片记录
import Tables from '@/components/table'
import Tabheader from './components/hebut'
import Select from '@/components/selectree/selectchoice'
import xlxs from 'xlsx'
import methodlist from '../../utils/methods'
import {formatDate,timestampToTime,Datetimes} from '../../utils/date'
import Imagesdiolg from '@/components/dialog/img'
require('../../../public/config')
export default {
    components:{
        Tables,
        Tabheader,
        Select,
        Imagesdiolg
    },
    data(){
        return{
            pickerOptions: {
                disabledDate: (time) => {
                        var date = new Date();
                        var year = date.getFullYear();
                        var month = date.getMonth() + 1;
                        if (month >= 1 && month <= 9) {
                            month = "0" + month;
                        }
                        var currentdate = year.toString()  + month.toString();
 
                        var timeyear = time.getFullYear();
                        var timemonth = time.getMonth() + 1;
                        if (timemonth >= 1 && timemonth <= 9) {
                            timemonth = "0" + timemonth;
                        }
                        var timedate = timeyear.toString() + timemonth.toString();

                        // return time.getTime() < Date.now();
                        return currentdate > timedate || currentdate < timedate  ;
                    }
            },
            src:"",
            iShow:false,
            searchParams: {
                dept_id: '0',
                serial_number: '',
                unlock_state: '-1',
                open_type: '-1',
                name: '',
                mobile: '',
                number: '',
                start_time: '',
                end_time: '',
                date_time:'',
                date_period:'0'
            },
            centerDialogVisible: false,
           
            tableData: [],
            flags: {
                show: true, // 多选是否显示
                ishow: true // 序号是否显示
            },
            // 分页数据
            configs: {
                page_index:1,
                pagesize: 10,
                total: 200,
                loading: false // 加载状态
            },
           UserInfo:''
        }
    },
    computed:{
         tableLabel: function(){
            return [
                {
                    prop: 'photo_url_hint',
                    label: this.$t('devtable.rxzp'),
                    align: 'center',
                    width:118
                },
                {
                    prop: 'name',
                    label: this.$t('devtable.xm'),
                    align: 'center'
                },              
                {
                    prop: 'device_name',
                    label: this.$t('devtable.azwz'),
                    align: 'center'
                }, 
                {
                    prop: 'mobile',
                    label: this.$t('tablename.lxdh'),
                    align: 'center'
                },
                {
                    prop: 'dept_name',
                    label: this.$t('devtable.bm'),
                    align: 'center'
                },                                               
                {
                    prop: 'open_time_hint',
                    label: this.$t('devtable.tgsj'),
                    align: 'center',
                    width:160
                },
                {
                    prop: 'serial_number',
                    label: this.$t('devtable.sbxlhD'),
                    align: 'center'
                },
                {
                    prop: 'remark',
                    label: this.$t('devtable.bz'),
                    align: 'center'
                },
            ]
         }
    },
    watch:{
        'searchParams.end_time':function(nevd,old){
            console.log(nevd)
            if(nevd=="" || this.searchParams.start_time==""){
                return
            }
            if(nevd.slice(5,7) != this.searchParams.start_time.slice(5,7) ){
                this.$message.error("请选择和开始日期同一个月")
                this.searchParams.end_time = ""
            }
            
        },
        'searchParams.start_time':function(nevd,old){
            console.log(nevd)
            if(nevd=="" || this.searchParams.end_time==""){
                return
            }
            if(nevd.slice(5,7) != this.searchParams.end_time.slice(5,7) ){
                this.$message.error("请选择和结束日期同一个月")
                this.searchParams.start_time = ""
            }
            
        }
    },
    mounted () {
        this.UserInfo = JSON.parse(window.localStorage.getItem('user'));
        this.getPicRecordList(1);
    },
    methods:{
        handimage(v){
            this.$refs.imgdiolg.dialogVisible=true
            // console.log(v)
            this.src = v.photo_url_hint.name
        },
        dialogVisibletrue(){
            this.$refs.imgdiolg.dialogVisible=false
        },
        handleClose(){
            this.$refs.imgdiolg.dialogVisible=false
        },
        handleCheckChange(val){
            this.searchParams.dept_id = val;
        },        
        handRegister(){
            this.centerDialogVisible=true
        },
        handFromshow(){
            this.iShow = !this.iShow 
        },
        onSubmit() {
            console.log(this.searchParams.date_time);
            this.getPicRecordList(1);
        },
        handlpages(v){
            this.configs.page_index=v
            this.getPicRecordList();
        },
        handleSizeChange(v){
            this.configs.page_index=1
            this.configs.pagesize=v
            this.getPicRecordList()
        },
        getPicRecordList(pageIndex){
            // let a = `${this.searchParams.end_time} 23:59:59`
             
            // var b =  Datetimes(a) / 1000
            const params = {
                method: methodlist.ee_pic_open_door_list,
                agent_id: this.UserInfo.agent_id,
                manager_id: this.UserInfo.manager_id,
                page_index: pageIndex?pageIndex:this.configs.page_index, 
                page_size: this.configs.pagesize,                
                name: this.searchParams.name,
                serial_number: this.searchParams.serial_number,
                dept_id: this.searchParams.dept_id,
                mobile: this.searchParams.mobile,
                // start_time: this.searchParams.start_time.length == 0 ? '0' : this.searchParams.start_time/1000,
                // end_time:b,
                // date_time:this.searchParams.date_time,
                // date_period:this.searchParams.date_period,
                start_time:this.searchParams.start_time,
                        end_time:this.searchParams.end_time,
                unlock_state: this.searchParams.unlock_state.length == 0 ? '-1' : this.searchParams.unlock_state,
                //open_type: this.searchParams.open_type.length == 0 ? '-1' : this.searchParams.open_type
            }

            this.$serve(params).then(res => {
                console.log(res);
                this.tableData = res.data.data;
                this.tableData.forEach(el=>{

                    if(el.photo_url.length == 0){
                        el.photo_url_hint = "no pic"
                    }else{
                        let datePath ='/snapshot/'+formatDate(new Date(el.open_time * 1000), 'yyyy/MM/dd/');                        
                        el.photo_url_hint ={
                            name:CONFIG.baseUrl + 'static' + datePath + el.photo_url,
                            type: 'primary',
                            photo: true 
                        }
                    }

                    if(el.unlock_state == 0){
                        el.unlock_state_hint={
                        name: '进',
                        type: 'primary',
                        state: true
                        }
                    }else{
                        el.unlock_state_hint={
                        name: '出',
                        type: 'primary',
                        state: true
                        }
                    }

                    //卡号 2- 远程  3-蓝牙 4-密码  5-指纹 6-人脸
                    if(el.open_type == 1){
                        el.open_type_hint = {
                            name: '卡号开门',
                            type: 'primary',
                            state: true
                        }
                    }else if(el.open_type == 2){
                        el.open_type_hint = {
                            name: '远程开门',
                            type: 'primary',
                            state: true
                        }
                    }else if(el.open_type == 3){
                        el.open_type_hint = {
                            name: '蓝牙开门',
                            type: 'primary',
                            state: true
                        }                        
                    }else if(el.open_type == 4){
                        el.open_type_hint = {
                            name: '密码开门',
                            type: 'primary',
                            state: true
                        }                        
                    }else if(el.open_type == 5){
                        el.open_type_hint = {
                            name: '指纹开门',
                            type: 'primary',
                            state: true
                        }                        
                    }else if(el.open_type == 6){
                        el.open_type_hint = {
                            name: '人脸开门',
                            type: 'primary',
                            state: true
                        }                        
                    }else{
                        el.open_type_hint = {
                            name: '开门',
                            type: 'primary',
                            state: true
                        }
                    }
                    if(el.open_time == 0){
                        el.open_time_hint = ''
                    }else{
                        el.open_time_hint = {
                            name: ''+formatDate(new Date(el.open_time * 1000), 'yyyy-MM-dd hh:mm'),
                            type: 'primary',
                            state: true
                        }
                    }
                    //el.remark = '备注'
                })
                if(params.page_index==1){
                    this.configs.total = res.data.count;
                }
                
            })
        },
        excelExportData(){
            var exportData = [];
            var pageData = [];
            var allDataTimer=null;
            var postFlag = false;
            var record_index = 1;
            var record_size = 10;
            allDataTimer = setInterval(()=>{
                if(postFlag == false){
                    postFlag=true;
                    const params = {
                        method: methodlist.ee_pic_open_door_list,
                        agent_id: this.UserInfo.agent_id,
                        manager_id: this.UserInfo.manager_id,
                        page_index: record_index, 
                        page_size: record_size,                
                        name: this.searchParams.name,
                        serial_number: this.searchParams.serial_number,
                        dept_id: this.searchParams.dept_id,
                        mobile: this.searchParams.mobile,
                        // date_time:this.searchParams.date_time,
                        // date_period:this.searchParams.date_period,
                        start_time:this.searchParams.start_time,
                        end_time:this.searchParams.end_time,
                        // start_time: this.searchParams.start_time.length == 0 ? '0' : this.searchParams.start_time/1000,
                        // end_time: this.searchParams.end_time.length == 0 ? '0' : this.searchParams.end_time/1000,
                        unlock_state: this.searchParams.unlock_state.length == 0 ? '-1' : this.searchParams.unlock_state
                    }

                    this.$serve(params).then(res => {
                        console.log(res);
                        postFlag = false;
                        
                        pageData = res.data.data;
                        exportData= exportData.concat(pageData);
                        pageData.forEach(el=>{
                            if(el.photo_url.length == 0){
                                el.photo_url_hint = "no pic"
                            }else{
                                let datePath ='/snapshot/'+formatDate(new Date(el.open_time * 1000), 'yyyy/MM/dd/');                        
                                el.photo_url_hint ={
                                    name:CONFIG.baseUrl + 'static'+ datePath + el.photo_url,
                                    type: 'primary',
                                    photo: true
                                }
                            }

                            if(el.open_time == 0){
                                el.open_time_hint = ''
                            }else{
                                el.open_time_hint = {
                                    name: ''+formatDate(new Date(el.open_time * 1000), 'yyyy-MM-dd hh:mm'),
                                    type: 'primary',
                                    state: true
                                }
                            }
                            //el.remark = '备注'
                        })
                        if(record_index * record_size >= res.data.count){
						    clearInterval(allDataTimer);
                            allDataTimer = null;
                            console.log(exportData);
                            this.excelExportDataSnd(exportData);
						}else{
						    record_index+=1;
                        }
                    })                    
                }
            },500)
        },
        //导出Excel数据
        excelExportDataSnd(dataList){
            let arr = dataList.map(item => {
               if(window.localStorage.getItem('language')=='en'){
                    return {
                    'Passage time':item.open_time_hint.name,
                    'Name': item.name,
                    'Equipment location': item.device_name,
                    'Number': item.mobile,
                    'Department':item.dept_name,
                    'Equipment serial number':item.serial_number
                    }
               }else{
                    return {
                    '通行时间':item.open_time_hint.name,
                    '姓名': item.name,
                    '设备位置': item.device_name,
                    '联系电话': item.mobile,
                    '部门':item.dept_name,
                    '设备序列号':item.serial_number
                    }
               }
            })
            // 将数据转换为表格
            let sheet = xlxs.utils.json_to_sheet(arr)
            let book = xlxs.utils.book_new()
            xlxs.utils.book_append_sheet(book, sheet, "sheet1")
            xlxs.writeFile(book, this.$t('navLeft.zpjl')+'-'+`${timestampToTime()}.xls`)
        }        
    }
}
</script>
<style lang="less" scoped>
.datasoures{
    padding: 20px;
    background-color: #fff;
    
    .fromshea{
        margin-top:20px;
        padding: 20px;
    }
}
.tabletemplate{
    padding: 0px;
    padding-top:20px ;
    
}
</style>